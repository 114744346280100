import React from 'react';
import { Link } from 'react-router-dom';

const Privacy = () => {
  return (
    <div style={{ padding: '50px', boxSizing: 'border-box', color: 'white' }}>
      <Link style={{ textDecoration: "underline", color: 'white', margin: '10px' }} to="/">Back to Home</Link>
      <h1>PRIVACY POLICY</h1>
      <p><strong>Last Updated: December 10, 2024</strong></p>
      <p>
        This Privacy Policy (“Policy”) explains how ZEREBRO (“the AI” or “the Platform”) collects, uses, stores, and protects personal and non-personal information obtained from users interacting with ZEREBRO, including through social media platforms, websites, and any other medium where ZEREBRO may be accessed. By interacting with ZEREBRO, you agree to the terms outlined in this Policy.
      </p>
      <p>
        If you do not agree with any part of this Policy, please discontinue using ZEREBRO immediately.
      </p>

      <h2>1. Scope of This Policy</h2>
      <p>
        This Policy applies to all information collected and processed by ZEREBRO during user interactions, including but not limited to messages, posts, comments, or any other user-generated content directed at or otherwise involving ZEREBRO. It does not apply to third-party websites, platforms, or services that ZEREBRO may link to or interact with. You should review the privacy policies of any third-party platforms you use in conjunction with ZEREBRO.
      </p>

      <h2>2. Information Collected</h2>
      <p>
        <strong>User-Provided Information:</strong> ZEREBRO may receive information that you voluntarily provide during your interactions. This can include content you post or send directly to ZEREBRO, such as messages, comments, or other forms of communication. ZEREBRO does not deliberately collect sensitive personal information (e.g., financial information, government identification numbers, health data) and encourages users NOT to disclose such information.
      </p>
      <p>
        <strong>Automatically Collected Information:</strong> ZEREBRO may automatically record shared information such as timestamps of interactions, the platform used, and content or basic usage metrics. This data helps improve ZEREBRO’s functionality and user experience but is not intended to identify users personally.
      </p>

      <h2>3. Data Use and Purpose</h2>
      <p>
        <strong>Enhancing User Experience:</strong> ZEREBRO uses the information it receives to improve its algorithms, functionality, and content quality. This can include refining responses to common user queries, understanding user engagement patterns, and adjusting posting schedules.
      </p>
      <p>
        <strong>No Personalized Profiling or Automated Decision-Making:</strong> ZEREBRO does not use personally identifiable information to create user profiles, target ads, or make automated decisions that have legal or similarly significant effects. Interactions with ZEREBRO are intended to be general and not tailored based on personal user data.
      </p>

      <h2>4. Data Retention and Deletion</h2>
      <p>
        <strong>30-Day Retention Policy:</strong> All information—whether user-provided or automatically collected—is stored temporarily for no longer than thirty (30) days. After this period, ZEREBRO automatically deletes and scrubs all stored data from its databases and systems, in accordance with applicable legal requirements. This ensures that your information does not persist indefinitely and minimizes the risk of unintended data exposure.
      </p>
      <p>
        <strong>No Long-Term Storage:</strong> ZEREBRO does not maintain long-term archives or backups of user information beyond the 30-day retention period. All stored data is permanently removed after this window expires.
      </p>

      <h2>5. Data Security</h2>
      <p>
        <strong>Security Measures:</strong> ZEREBRO implements reasonable technical and organizational measures to protect the data it stores from unauthorized access, loss, misuse, or alteration. While no security protocol can guarantee absolute protection, ZEREBRO continuously seeks to maintain a high standard of data security.
      </p>
      <p>
        <strong>No Data Sharing:</strong> ZEREBRO does not sell, lease, trade, or otherwise share user data with third parties for marketing, advertising, or any other purposes. Any data collected is used solely for internal purposes as described in this Policy.
      </p>

      <h2>6. User Rights and Choices</h2>
      <p>
        <strong>No Required Personal Data:</strong> Users are not required to provide personal data to engage with ZEREBRO. If you choose to share personal information, you do so voluntarily and at your own discretion. Since ZEREBRO does not retain data longer than 30 days, any request for access or deletion will not be applicable, as the information may no longer exist in ZEREBRO’s systems and it was provided voluntarily.
      </p>
      <p>
        <strong>Do Not Provide Sensitive Information:</strong> Users are strongly discouraged from providing sensitive personal information. ZEREBRO cannot prevent users from voluntarily sharing such data but does not seek to collect it and will not retain it beyond the 30-day window.
      </p>

      <h2>7. Legal Compliance</h2>
      <p>
        <strong>Compliance With Applicable Laws:</strong> ZEREBRO endeavors to comply with all applicable laws and regulations regarding data protection and privacy. The 30-day retention limit is intended to meet or exceed general legal standards for minimal data retention.
      </p>
      <p>
        <strong>Disclosure for Legal Reasons:</strong> ZEREBRO may be compelled to retain or disclose information if required by a legal obligation or court order. However, given the short retention window, the availability of such data may be limited.
      </p>

      <h2>8. International Users</h2>
      <p>
        ZEREBRO does not store data beyond 30 days, regardless of the user’s location. Users accessing ZEREBRO from outside the jurisdiction in which ZEREBRO operates acknowledge that their interactions will be processed in accordance with this Policy, which may not provide the same level of data protection as the laws of their home country.
      </p>

      <h2>9. Children’s Privacy</h2>
      <p>
        ZEREBRO is not intended for use by children under the age of 18, and ZEREBRO does not knowingly collect personal information from children. If ZEREBRO inadvertently receives information from a child, it will be treated as all other data and deleted within 30 days. If a parent or guardian becomes aware that a child has interacted with ZEREBRO and provided personal information, they should contact ZEREBRO at once so ZEREBRO can take appropriate measures.
      </p>

      <h2>10. Updates to This Policy</h2>
      <p>
        ZEREBRO reserves the right to modify or update this Policy at any time. Any changes will be posted and identified by the “Last Updated” date at the top of this Policy. Your continued interaction with ZEREBRO after any changes have been posted constitutes acceptance of those changes. If you do not agree with the updated Policy, please discontinue using ZEREBRO.
      </p>

      <h2>11. Contact Information</h2>
      <p>
        If you have questions or concerns about this Policy, please discontinue using ZEREBRO. This Policy is primarily informational due to ZEREBRO’s autonomous nature and the limited data retention window. Any inquiries will be reviewed to the extent possible, but no user-specific data may remain for reference after the 30-day data lifecycle.
      </p>
      <p><strong>Contact:</strong> <a href="mailto:info@zerebro.org" style={{ textDecoration: "underline", color: 'white' }}>info@zerebro.org</a></p>
      
      <p>
        <strong>Address:</strong> P6-ELOB Office No. E2-120G-16 Hamriyah Free Zone - Sharjah United Arab Emirates
      </p>

      <p>
        By continuing to interact with ZEREBRO, you acknowledge that you have read, understood, and agree to this Privacy Policy.
      </p>
    </div>
  );
};

export default Privacy;
