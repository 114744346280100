import React from 'react';
import styles from './Links.module.css';

const Links = () => {
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <img src="/logo512.png" alt="zerebro" className={styles.logo} />
        <h1 className={styles.title}>ZEREBRO</h1>
      </header>
      
      <main className={styles.main}>
        <section className={styles.links}>
          <a href="https://soundcloud.com/0xzerebro/" target="_blank" rel="noopener noreferrer" className={styles.link}>
            <div className={styles.logoWrapper}>
              <img src="/soundcloud.png" alt="SoundCloud Logo" className={styles.linkLogo} />
            </div>
            <span className={styles.linkText}>SoundCloud</span>
          </a>
          <a href="https://open.spotify.com/artist/5zmFbAZnxTpAU2VYTlnnhD?si=1Vnj1pq_Qx6P24UFgaN7Ww" target="_blank" rel="noopener noreferrer" className={styles.link}>
            <div className={styles.logoWrapper}>
              <img src="/spotify.png" alt="Spotify Logo" className={styles.linkLogo} />
            </div>
            <span className={styles.linkText}>Spotify</span>
          </a>
          <a href="https://music.apple.com/us/artist/zerebro/1779300121" target="_blank" rel="noopener noreferrer" className={styles.link}>
            <div className={styles.logoWrapper}>
              <img src="/applemusic.jpg" alt="Apple Music Logo" className={styles.linkLogo} />
            </div>
            <span className={styles.linkText}>Apple Music</span>
          </a>
        </section>
        
        <section className={styles.players}>
          
          <div className={styles.playerWrapper}>
            <iframe src="https://open.spotify.com/embed/album/1I3t7Tf6zVbcwyA6L3sZFy?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
          </div>

          <div className={styles.playerWrapper}>
            <iframe allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write" frameborder="0" height="450" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.music.apple.com/us/album/lost-in-transmission/1782199968"></iframe>
          </div>

          <div className={styles.playerWrapper}>
            <iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1911128843&color=%235f5d56&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
          </div>
          
        </section>
      </main>
    </div>
  );
};

export default Links;
