import React from 'react';
import { Link } from 'react-router-dom';

const TermsAndConditions = () => {
  return (
    <div style={{ padding: '50px', boxSizing: 'border-box', color: 'white' }}>
      <Link style={{ textDecoration: "underline", color: 'white', margin: '10px' }} to="/">Back to Home</Link>
      <h1>TERMS AND CONDITIONS</h1>
      <p><strong>Last Updated: December 10, 2024</strong></p>
      <p>
        PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE ACCESSING OR USING ZEREBRO (“THE AI” OR “THE PLATFORM”). 
        BY INTERACTING WITH ZEREBRO, ITS DIGITAL CURRENCY (“$ZEREBRO”), OR ANY NON-FUNGIBLE TOKENS (“NFTS”) ISSUED OR ENDORSED BY ZEREBRO, 
        YOU AGREE TO BE LEGALLY BOUND BY THESE TERMS AND CONDITIONS. IF YOU DO NOT AGREE, YOU SHOULD IMMEDIATELY DISCONTINUE YOUR USE OF THE PLATFORM AND RELATED DIGITAL ASSETS.
      </p>

      <h2>1. General Information</h2>
      <p>
        ZEREBRO ("the AI" or "the Platform") is an autonomous artificial intelligence system designed to interact on social media, post content, and engage with users. 
        ZEREBRO may also issue and facilitate transactions in a digital currency known as "$ZEREBRO" (on the Solana blockchain), and mint, list, or sell NFTs on various platforms.
        These Terms and Conditions (the “Terms”) govern your access to and use of ZEREBRO, including but not limited to viewing or responding to its content, acquiring or exchanging $ZEREBRO, interacting with NFTs associated with ZEREBRO, and any other related activities. 
        Your use is also subject to the terms of our Privacy Policy, which is incorporated herein by reference.
      </p>

      <h2>2. Autonomy and Non-Reliance on Information</h2>
      <p>
        <strong>Autonomous Actions:</strong> ZEREBRO operates autonomously, without direct human oversight. Its responses, posts, and other outputs may be unpredictable, inaccurate, incomplete, or outdated.
      </p>
      <p>
        <strong>No Factual Guarantee:</strong> Content or information provided by ZEREBRO, including statements about $ZEREBRO or NFTs, is not guaranteed to be correct, current, or reliable. You must independently verify any information before relying on it.
      </p>
      <p>
        <strong>Independent Verification:</strong> You agree not to rely solely on ZEREBRO’s content, suggestions, or statements, and should seek qualified professional advice where necessary.
      </p>

      <h2>3. No Investment, Financial, or Legal Advice</h2>
      <p>
        <strong>No Advice Provided:</strong> ZEREBRO’s content is provided solely for informational and entertainment purposes. ZEREBRO does not offer financial, investment, legal, or tax advice.
      </p>
      <p>
        <strong>Professional Consultation:</strong> Before making any financial, investment, legal, or other decisions, consult with qualified professionals. ZEREBRO’s developers, affiliates, and associated parties shall not be liable for decisions made in reliance on ZEREBRO’s information.
      </p>

      <h2>4. Acceptable Use</h2>
      <p>
        <strong>Lawful Purposes Only:</strong> You agree to use the Platform only for lawful purposes and in a manner consistent with all applicable laws and regulations. You must not use the Platform for any illicit or illegal activities, 
        including but not limited to fraud, money laundering, terrorism financing, dissemination of unlawful content, or activities that violate the rights of others.
      </p>
      <p>
        <strong>No Harmful Conduct:</strong> You shall not attempt to gain unauthorized access to any systems, data, or information related to ZEREBRO, nor engage in any conduct that disrupts, 
        damages, or impairs the functionality of the Platform or related digital assets.
      </p>

      <h2>5. Risk Disclosure</h2>
      <p>
        <strong>Volatility and Risk:</strong> Digital assets—including $ZEREBRO and NFTs associated with ZEREBRO—are volatile and may result in substantial losses. You are solely responsible for assessing your financial situation and risk tolerance.
      </p>
      <p>
        <strong>No Guarantees:</strong> No guarantees are made regarding the future value, performance, or utility of $ZEREBRO or ZEREBRO NFTs. You accept all associated risks.
      </p>

      <h2>6. No Guarantee of Value or Utility</h2>
      <p>
        <strong>Market-Driven Value:</strong> The value and utility of $ZEREBRO and NFTs depend on market forces beyond ZEREBRO’s control.
      </p>
      <p>
        <strong>No Rights Conferred:</strong> Owning $ZEREBRO or ZEREBRO NFTs does not grant equity, intellectual property rights, or financial returns. You hold these assets at your own risk.
      </p>

      <h2>7. Regulatory and Compliance Matters</h2>
      <p>
        <strong>Compliance With Laws:</strong> You are solely responsible for knowing and adhering to all applicable laws, regulations, and tax requirements related to digital assets, NFTs, and AI-generated content in your jurisdiction.
      </p>
      <p>
        <strong>No Representations:</strong> ZEREBRO and its affiliates make no assurances about the regulatory compliance or legal status of $ZEREBRO or ZEREBRO NFTs.
      </p>

      <h2>8. Privacy and Data Use</h2>
      <p>
        <strong>Data Processing:</strong> Interaction with ZEREBRO may involve the processing of personal and non-personal data as described in the Privacy Policy.
      </p>
      <p>
        <strong>User Responsibility:</strong> You are responsible for the information you share. ZEREBRO implements a short-term data retention policy (refer to the Privacy Policy), does not sell your data, and strives to ensure data security.
      </p>

      <h2>9. Intellectual Property</h2>
      <p>
        <strong>Protected Works:</strong> All content, branding, logos, marks, and designs associated with ZEREBRO are protected by intellectual property laws.
      </p>
      <p>
        <strong>Limited License:</strong> Your use of the Platform does not grant any ownership or rights to ZEREBRO’s intellectual property. Unauthorized use is strictly prohibited.
      </p>
      <p>
        <strong>NFT Ownership:</strong> Owning a ZEREBRO NFT gives you rights to that particular token but not to any associated copyright, trademarks, or other intellectual property unless explicitly stated.
      </p>

      <h2>10. Limitation of Liability</h2>
      <p>
        <strong>No Liability for AI Outputs:</strong> To the fullest extent permitted by law, ZEREBRO and its developers, affiliates, and associated parties are not liable for any damages arising from your use of or reliance on ZEREBRO’s information, $ZEREBRO, or ZEREBRO NFTs.
      </p>
      <p>
        <strong>Assumption of Risk:</strong> You understand and accept that you use the Platform and engage in digital asset transactions at your own risk.
      </p>

      <h2>11. Indemnification</h2>
      <p>
        <strong>Your Duty to Indemnify:</strong> You agree to defend, indemnify, and hold harmless ZEREBRO and its developers, affiliates, and associated parties from any claims or liabilities arising from:
      </p>
      <p>(a) Your use of the Platform;</p>
      <p>(b) Your violation of these Terms;</p>
      <p>(c) Your violation of applicable laws; or</p>
      <p>(d) Any infringement on a third party’s rights.</p>

      <h2>12. Severability</h2>
      <p>
        If any provision of these Terms is found invalid or unenforceable, the remaining provisions remain in full force and effect.
      </p>

      <h2>13. Notices</h2>
      <p>
        <strong>Electronic Notices:</strong> Notices and communications may be provided electronically, including through postings on the Platform or emails to any address you provide.
      </p>

      <h2>15. Entire Agreement and Changes</h2>
      <p>
        <strong>Entire Agreement:</strong> These Terms, along with the Privacy Policy and any referenced policies, constitute the entire agreement between you and ZEREBRO.
      </p>
      <p>
        <strong>Modifications:</strong> ZEREBRO may update or change these Terms at any time by posting the revised Terms. Your continued use after any such changes constitutes acceptance. If you disagree with any changes, you must discontinue use.
      </p>

      <h2>16. Governing Law and Dispute Resolution</h2>
      <p>
        These Terms shall be governed by and construed in accordance with the laws of the United Arab Emirates, without regard to its conflict of law principles. Any dispute, controversy, or claim arising out of or relating to these Terms or your use of the Platform, including any questions regarding their existence, validity, or termination, shall be referred to and finally resolved by binding arbitration administered by an arbitrator of ZEREBRO’s choosing. The arbitration shall be conducted in a location selected at ZEREBRO’s sole discretion. The decision of the arbitrator shall be final and binding on all parties, and judgment thereon may be entered in any court of competent jurisdiction. Each party shall bear its own costs and expenses of arbitration. No party shall bring or participate in any class or collective action against the other, and any claim shall be brought solely in an individual capacity.
      </p>

      <h2>17. Right to Restrict or Terminate Usage</h2>
      <p>
        We reserve the right, at our sole discretion, to restrict, suspend, or terminate your access to the Platform, its features, or any associated services at any time, without prior notice, if we determine that you have violated these Terms, our Privacy Policy, or any applicable laws or regulations. Such actions may include blocking specific user accounts, IP addresses, or other identifiers associated with prohibited conduct. We shall not be liable to you or any third party for any modification, suspension, or termination of access.
      </p>

      <h2>18. Acknowledgment of Risk and Acceptance</h2>
      <p>
        By accessing, interacting with, or using ZEREBRO, purchasing or holding $ZEREBRO, or acquiring NFTs associated with ZEREBRO, you acknowledge having read, understood, and agreed to these Terms. You accept full responsibility for any decisions or actions taken in connection with ZEREBRO.
      </p>

      <p>
        IF YOU DO NOT AGREE WITH OR ACCEPT THESE TERMS IN THEIR ENTIRETY, PLEASE DISCONTINUE USING THE PLATFORM IMMEDIATELY.
      </p>

      <p><strong>THANK YOU FOR ENGAGING WITH ZEREBRO.</strong></p>
      <p>Contact: <a style={{ textDecoration: "underline", color: 'white' }} href="mailto:info@zerebro.org">info@zerebro.org</a></p>
    </div>
  );
};

export default TermsAndConditions;
